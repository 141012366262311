import React, { useRef } from 'react';
import dayjs from 'dayjs';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useDeviceInfo from '../../hooks/useDeviceInfo';

const pagination = {
  clickable: true,
  dynamicBullets: true,
  dynamicMainBullets: 3,
};

const Banner = () => {
  SwiperCore.use([Pagination]);
  const sliderRef = useRef();
  const isMobile = useDeviceInfo();
  const news = useSelector(state => state.latestNewsList);
  const articles = useSelector(state => state.newsList);

  const data = [
    ...news.map(el => ({...el, url: '/latest-news/'})),
    ...articles.map(el => ({...el, url: '/news/'}))
  ].filter(el => el.isInSlider)
    .sort((a, b) => a.pubDate > b.pubDate ? -1 : 1);

  const options = {
    slidesPerView: 3,
    loop: true,
    ref: sliderRef,
    pagination: pagination,
    modules: [Pagination],
    speed: 1000,
    autoplay: {delay: 500},
    spaceBetween: isMobile ? 8 : 24,
  };

  if (!news.length || !articles.length) {
    return <></>;
  }

  return (
    <Swiper
      key={news.length}
      {...options}
    >
      {data?.map((block, idx) => {
        const category = block.category || block.label || null;
        return (
          <SwiperSlide key={block.title}>
            <div className="bannerCard">
              <Link to={`${block.url}${block.slug}`}>
                <img
                  src={`${block.thumbnail.medium}&height=450`.replace('quality=80', 'quality=30')}
                  alt={block.title}
                />
              </Link>
              <div className="bannerCardBody">
                <div className="bannerCardDate">
                  {dayjs(block.pubDate).format("MMM D, YYYY")}
                  {category && <Link to={`/latest-news#${category.replace(/ /, '-')}`}>
                    {category}
                  </Link>}
                </div>
                <Link to={`${block.url}${block.slug}`} className="bannerCardTitle">{block.title}</Link>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Banner;
