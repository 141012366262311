import React from 'react';

import useTranslate from '../../../../hooks/useTranslate';

import gif from './img/Russian_Empire_1914.gif';

import './Empire.scss';
import Section from '../../../../components/ContentSection';

const HowToHelp = () => {
  const { t } = useTranslate();

  return (
    <Section className="empire">
      <div className="commonTitle">{t('war.empire')}</div>
      <div className="commonInfo">
        {t('war.empireSubtitle')} <br/>
        {t('war.empireInfo')}
      </div>
      <div className="empireVisual">
        <img src={gif} alt={t('war.empire')} />
      </div>
    </Section>
  );
};

export default HowToHelp;
