import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import useDeviceInfo from '../../../hooks/useDeviceInfo';
import Thumb from './Thumb';
import Arrows from '../../Arrows';

import './Youtube.scss';

const Youtube = ({ setCurrentId, setCurrentPreview, flareSet }) => {
  const [videos, setVideos] = useState([]);
  const slickRef = useRef(null);
  const isMobile = useDeviceInfo();

  useEffect(() => {
    const thumbPre = '';
    // const thumbPre = process.env.NODE_ENV === 'development' ? '' : '/cdn-cgi/image/format=auto,quality=75/';
    const videosData = flareSet.map(el => ({
      title: el.title,
      thumbnail: el.customThumbUrl || `${thumbPre}https://cloudflarestream.com/${el.videoUrl}/thumbnails/thumbnail.jpg?height=360&time=7s`,
      guid: el.videoUrl,
    }))

    setVideos(videosData);

    const latest = videosData[0];
    const videoId = latest.guid;
    setCurrentId(videoId);
    setCurrentPreview({src: latest.thumbnail, alt: latest.title});
  }, []);

  const handleClick = (video) => {
    setCurrentId(video.guid);
    setCurrentPreview({src: video.thumbnail, alt: video.title});
  };

  return (
    <div className="thumbs">
      <div className="thumbsContainer">
        <Swiper
          ref={slickRef}
          slidesPerView={5}
          speed={1000}
          loop={isMobile}
        >
          {videos.map(video => (
            <SwiperSlide key={video.guid}>
              <Thumb video={video} onClick={handleClick} />
            </SwiperSlide>
            ))}
        </Swiper>
      </div>
      <Arrows
        onClickPrev={() => slickRef.current.swiper.slidePrev()}
        onClickNext={() => slickRef.current.swiper.slideNext()}
        classPrefix="thumbs"
      />
    </div>
  );
};

export default Youtube;
