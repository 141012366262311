import React from 'react';
import { Stream } from '@cloudflare/stream-react';

import './Video.scss';

const Video = ({ id, autoplay = false, poster = '', className = '' }) => {
  if (!id) {
    return <></>;
  }

  return (
    <Stream
      controls
      src={id}
      width="640"
      height="420"
      className={`video videoVm ${className}`}
      autoplay={autoplay}
      poster={poster}
    />
  );
};

export default Video;
