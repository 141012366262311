import React from 'react';

import useTranslate from '../../../../hooks/useTranslate';
import Video from '../../../../components/Socials/Youtube/Video';
import Button from '../../../../components/Button';
import Section from '../../../../components/ContentSection';

import './Weapons.scss';

const Weapons = () => {
  const { t, content } = useTranslate();

  return (
    <Section className="weapons">
      <div className="commonTitle">{t('war.whatNeeds')}</div>

      <div className="weaponsContainer">
        <div className="weaponsVideo">
          <Video id="970ea4ccf6d8064e8df7866012a5e0bf" autoplay={false} />
        </div>
        <div className="weaponsInfo">
          <div className="weaponsSubtitle">{t('war.whatNow')}</div>
          {t('war.whatCitation')} <span>{t('war.whatZelensky')}</span>
          <Button to="/what-does-ukraine-need" red>{t('war.learnMore')}</Button>
        </div>
      </div>
    </Section>
  );
};

export default Weapons;
