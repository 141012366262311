import React from 'react';

import ImagesFrom, { BUCHA_PHOTOS, RUSSIA_PHOTOS } from '../../components/ImagesFromUA/ImagesFrom';
import Videos, { UKRAINE_VIDS } from '../../components/VideosFromUA/Videos';
import Links from '../../components/Socials/Links';
import WhatHappens from './components/WhatHappens';
import Empire from './components/Empire/Empire';
import WhatWeNeed from './components/WhatUkraineNeeds';
import Weapons from './components/Weapons';
import './War.scss';

const WarInUa = () => {
  return (
    <div className="war">
      <Links />
      <WhatHappens />
      <ImagesFrom type={RUSSIA_PHOTOS} />
      <ImagesFrom type={BUCHA_PHOTOS} />
      {/*<Videos type={UKRAINE_VIDS} />*/}
      <Empire />
      <Weapons />
      <WhatWeNeed />
    </div>
  );
};

export default WarInUa;
