import React from 'react';

import useTranslate from '../../../../hooks/useTranslate';

import './Cards.scss';

const WhatHappensCard = ({ data }) => {
  const { t } = useTranslate();

  const Image = data.imgPath;

  return (
    <div className="commonCard">
      <Image className="commonCardImg" />
      <div className="commonCardTitle">{data.title} {t(data.translate)}</div>
      <div className="commonCardSubtitle">{t(data.info)}</div>
    </div>
  );
};

export default WhatHappensCard;
