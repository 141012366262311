import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';

import Section from '../../../../components/ContentSection';
import useTranslate from '../../../../hooks/useTranslate';
import WhatHappensCard from '../CommonCards/Cards';

import info from './whatHappensContent';

import './WhatHappens.scss';

const pagination = {
  clickable: true,
  dynamicBullets: true,
  dynamicMainBullets: 3,
};

const WhatHappens = () => {
  SwiperCore.use([Pagination]);
  const { t } = useTranslate();
  const sliderRef = useRef();

  const options = {
    slidesPerView: 3,
    loop: true,
    ref: sliderRef,
    pagination: pagination,
    modules: [Pagination],
    speed: 1000,
    spaceBetween: 8,
  };

  return (
    <Section className="whatHappens">
      <div className="commonTitle">{t('war.title')}</div>
      <div className="commonInfo">
        {t('war.invasion')}
      </div>
      <div className="whatHappensCards">
        {info.map(block => <WhatHappensCard data={block} key={block.title} />)}
      </div>
      <div className="whatHappensCardsMob">
        <Swiper
          {...options}
        >
          {info.map(block => (
            <SwiperSlide key={block.title}>
              <WhatHappensCard data={block} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Section>
  );
};

export default WhatHappens;
