import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import useTranslate from '../../hooks/useTranslate';
import Youtube from '../Socials/Youtube';
import Section from '../ContentSection';
import Video from '../Socials/Youtube/Video';

import { ReactComponent as PlayBtn } from './img/play.svg';

import './Videos.scss';

export const UKRAINE_VIDS = 'ukraine_vids';

const content = {
  [UKRAINE_VIDS]: {
    title: 'war.videos',
    info: 'war.videosInfo',
    id: 'russian-invasion-videos',
    flareSet: [
      {
        title: 'Three month of war in Ukraine',
        videoUrl: '78f9fc19638b6cebd492070fdfce4edb'
      },
      {
        title: 'BBC: Ukrainian cities after russian invasion',
        videoUrl: '09517785a52ece455974a3c8985ea37d'
      },
      {
        title: 'Footage of Azovstal destruction',
        videoUrl: '9fed036eeea49d627a0a39144ea2e7b6'
      },
      {
        title: 'Destruction after shelling by russian army forces',
        videoUrl: '9527f0243f797ce12201f2c19ee3f242'
      },
      {
        title: 'What happened to Kharkiv after russian invasion',
        videoUrl: 'bc93986b9a78f7c1dad59278b1566df2'
      },
      {
        title: 'Russian missiles strike residential area in Ukraine',
        videoUrl: 'e785026af42db4f5e5da6696f2c10c6c'
      },
      {
        title: 'Missile strike at Krarkiv amusement park, famous among local children',
        videoUrl: 'ca00cc5bfcc2052f941adf9fd982151e'
      },
      {
        title: 'Destruction of residential buildings',
        videoUrl: '8a5a938bcd90b6ed4b181540d07c4ea4'
      }
    ],
  },
};

const Videos = ({ type, customGallery = null }) => {
  const [currentVideoId, setCurrentVideoId] = useState('');
  const [currentPreview, setCurrentPreview] = useState('');
  const [clicked, setClicked] = useState(false);

  if (customGallery !== null) {
    content[type] = customGallery;
  }

  const { t } = useTranslate();

  useEffect(() => {
    setClicked(false);
  }, [currentVideoId]);

  return (
    <Section className="videos">
      <a id={content[type].id} className="sub-anchor"></a>
      <div className="videosPreview">
        <div className="videosImgContainer" onClick={() => setClicked(true)}>
          <img src={currentPreview.src} alt={currentPreview.alt} className={cn({
            'videosImgHide': clicked,
          })} />
          {clicked ?
            <Video id={currentVideoId} />
            :
            <div className="videosPlayBtn">
              <PlayBtn />
            </div>
          }
        </div>

        <div className="videosTextBlock">
          <div className="videosTitle">{t(content[type].title)}</div>
          <div className="videosInfo">
            {t(content[type].info)}
          </div>
        </div>
      </div>
      <div className="videosList">
        <Youtube
          setCurrentId={setCurrentVideoId}
          setCurrentPreview={setCurrentPreview}
          flareSet={content[type].flareSet}
        />
      </div>
    </Section>
  );
};

export default Videos;
