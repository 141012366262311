import { ReactComponent as icon1 } from './img/shape-icons-1.svg';
import { ReactComponent as icon2 } from './img/shape-icons-2.svg';
import { ReactComponent as icon3 } from './img/shape-icons-3.svg';
import { ReactComponent as icon4 } from './img/shape-icons-4.svg';
import { ReactComponent as icon5 } from './img/shape-icons-5.svg';
import { ReactComponent as icon6 } from './img/shape-icons-6.svg';

export default [
  {
    title: '> 2 960',
    info: 'war.card1',
    imgPath: icon1,
  },
  {
    title: '> 4 700',
    info: 'war.card2',
    imgPath: icon2,
  },
  {
    title: '> 25 000',
    info: 'war.card3',
    imgPath: icon3,
  },
  {
    title: '> 6M',
    info: 'war.card4',
    imgPath: icon4,
  },
  {
    title: '> 500',
    info: 'war.card5',
    imgPath: icon5,
  },
  {
    title: '',
    translate: 'war.times',
    info: 'war.card6',
    imgPath: icon6,
  },
]
