import React, { useRef } from 'react';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';

import useTranslate from '../../../../hooks/useTranslate';
import WhatHappensCard from '../CommonCards/Cards';
import Button from '../../../../components/Button';
import Section from '../../../../components/ContentSection';

import info from './whatNeededContent';

import './WhatUaNeeds.scss';

const pagination = {
  clickable: true,
  dynamicBullets: true,
  dynamicMainBullets: 3,
};

const WhatUaNeeds = () => {
  SwiperCore.use([Pagination]);
  const { t } = useTranslate();
  const sliderRef = useRef();

  const options = {
    slidesPerView: 3,
    loop: true,
    ref: sliderRef,
    pagination: pagination,
    modules: [Pagination],
    speed: 1000,
    spaceBetween: 8,
  };

  return (
    <Section className="whatUkraineNeeds">
      <div className="commonInfo">
        {t('war.whatInfo')}
      </div>
      <div className="whatUkraineNeedsCards">
        {info.map((block, idx) => <WhatHappensCard data={{
          imgPath: block,
          title: t(`war.whatCardTitle${idx + 1}`),
          info:  t(`war.whatCardInfo${idx + 1}`),
        }} key={`card-${idx}`} />)}
      </div>
      <div className="whatUkraineNeedsCardsMob">
        <Swiper
          {...options}
        >
        {info.map((block, idx) => (
          <SwiperSlide key={`mobcard-${idx}`}>
            <WhatHappensCard data={{
              imgPath: block,
              title: t(`war.whatCardTitle${idx + 1}`),
              info:  t(`war.whatCardInfo${idx + 1}`),
            }} />
          </SwiperSlide>
        ))}
        </Swiper>
      </div>
      <div className={cn('commonInfo', 'commonSubInfo')}>
        <span>{t('war.whatMore')}</span>
        <Button to="/what-does-ukraine-need" red>{t('war.learnMore')}</Button>
      </div>
    </Section>
  );
};

export default WhatUaNeeds;
