import React from 'react';

import './Thumb.scss';

const Thumb = ({ video, onClick }) => {
  if (!video) {
    return <></>;
  }

  return (
    <img
      src={video.thumbnail || ''}
      className="thumb"
      onClick={() => onClick(video)}
      title={video.title}
      alt={video.title}
    />
  );
};

export default Thumb;
